<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="@/assets/images/logo/logo.png" />
    </Header>
    <!-- End Header Area -->
    <!-- Start Column Area  -->
    <div  class="rn-column-area rn-section-gap bg_color--5 mt--150">
          <v-row>
                  <v-col lg="6"
                  md="6"
                  sm="12"
                  cols="12">
                <v-row align="center" justify="center" >
                  <v-col align="center" justify="center" >
                    <v-img data-aos="fade-left" data-aos-duration="1200" width="650" src="@/assets/images/blog/my_history.png" alt="Creative Agency" />
                  </v-col>
                </v-row>
                <v-row align="center" justify="center" >
                  <p>Κατέβασε το app</p><br>
                </v-row>
                <v-row align="center" justify="center" >
                  <v-col align="right">
                    <a href="https://apps.apple.com/us/app/id1489983713"  target="blank" class="app-btn1"><v-img width="100" src="@/assets/images/blog/appstore-tra-grey.png" alt=""/></a>
                  </v-col>
                  
                  <v-col>
                    <a href="https://play.google.com/store/apps/details?id=com.rcflavorproba"  target="blank" class="app-btn2"><v-img width="100" src="@/assets/images/blog/googleplay-tra-grey.png" alt="" /></a>
                  </v-col>
                </v-row>
            </v-col>
               <v-col lg="5"
                     md="5"
                     sm="12"
                     cols="12">

                  <div class="single-service text-white mb--50 mt--50 container" >
                  
                  <div  v-if="this.transactions.transactions.length == 0">
                    Δεν υπάρχουν δεδομένα
                  </div>
                  <div v-else class="single-service text-white mb--50">
                          <v-row>
                                  <v-col lg="6" md="6"  sm="6" xs="6" cols="12" >
                                      <p class="mb--20"> Στοιχεία Συναλλαγής </p>
                                      
                                    
                                  </v-col>
                                  <v-col class="mb--20" lg="6" md="6"  sm="6" xs="6" cols="12" >
                                    <p> Ποσό </p>
                                    
                                </v-col>
                               </v-row>
                               <hr>
                          <div id="transactionsList" v-for="(transaction, i) in transactions.transactions"
                              :key="i">
                              <v-row>
                                  <v-col lg="6" md="6"  sm="6" xs="6" cols="12" >
                                      <p> {{ transaction.transaction_type_system === 'exchange_points_with_coupon' && transaction.total_points < 0 ?
                                                         'Εξαργύρωση πόντων' : transaction.store.name }} </p>
                                                                             <!-- v-if="!['refund'].includes(transaction.transaction_type_system) -->
                                      <p><img src="@/assets/images/logo/logo_map.png" width="30px" style="display: inline-block;" />
                                {{ ['refund'].includes(transaction.transaction_type_system) ? transaction.total_points + " πόντοι" : transaction.total_points + " πόντοι" }} 
                              </p>
                                  </v-col>
                                  <v-col lg="6" md="6"  sm="6" xs="6" cols="12" >
                                    <!-- <p> {{ transaction.total_price }} {{ transaction.currency }} </p> -->
                                     <p v-if="['order_via_mobile_app','order_pos','order_via_android_pos','order_via_store_manager'].includes(transaction.transaction_type_system)" 
                            >{{  transaction.total_price + " " + transaction.currency }}</p>
                             <p v-else-if="['refund'].includes(transaction.transaction_type_system)" >{{ '-' + transaction.total_price + " " + transaction.currency }}</p>
                             <p v-else >{{ '-' }}</p>
                                  
                                </v-col>
                               </v-row>
                               <hr>
                          </div>
                      
                    </div>
                  </div>
              </v-col>
                           
          </v-row>
          <b-pagination
            class="mt--100"
            v-if="this.transactions.pagination.total_pages > 1"
            v-model="currentPage"
            v-on:click.native="scrollToTop"
            :per-page="this.transactions.pagination.items_per_page"
            :total-rows="this.transactions.pagination.total"
            aria-controls="transactionsList"
            align="center"
          ></b-pagination> 
    </div>
    <!-- End Column Area  -->

    <Footer />
  </div>
</template>

<script>
  import Header from "@/components/header/Header";
  import Footer from "@/components/footer/FooterTwo";
  import axios from 'axios';
  import { mapMutations, mapActions, mapGetters } from "vuex";

  export default {
    components: {
      Header,
      Footer,
    },
    data() {
      return {
        currentPage: 1,
        loading: true,
      }
    },
     watch: {
      currentPage: async function (newQuestion, oldQuestion) {
        await this.getTransactions(newQuestion);
      }
    },
    computed: {
       ...mapGetters (["transactions"]),
       ...mapGetters (["user"]),

      rows() {
        return this.transactions.length
      }

    },
    async created () {
      if(!this.user){
        this.$loading(true);
        await this.getUser();
        this.$loading(false);
        if(!this.user){
         this.$router.push('/')
         return
        }
      }
      if(!this.transactions){
        this.$loading(true);
        await this.getTransactions(1);
        this.$loading(false);
      }else{
        await this.getTransactions(1);
      }
    },
    methods: {
      scrollToTop() {
        this.$vuetify.goTo(0);
      },
      ...mapActions(["getUser"]),
      ...mapActions(["getTransactions"]),
    },
  };
</script>

<style scoped>
.table {
    overflow-y: auto;
}
.table::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(83, 81, 81, 0.3);
    background-color: #004a91;
    border-radius: 1000px;
}

.table::-webkit-scrollbar {
    width: 5px;
    background-color: #004a91;
}

.table::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    border-radius: 10px;
}
img {
    display: block;
    margin: auto;
    transition: all 10s ease;
}
</style>